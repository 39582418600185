/**
 * /*----元素初始化----start----
 *
 * @format
 */

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
table {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

html,
body {
    height: 100%;
}

body {
    font-family: Roboto,San Francisco,Helvetica Neue,Helvetica,Arial,PingFangSC-Light,Hiragina Sans GB,WenQuanYi Micro Hei,microsoft yahei ui,microsoft yahei,sans-serif;
    background: #fff;
}

.clear {
    clear: both;
    height: 0;
    line-height: 0;
    font-size: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #dfdfdf;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #dfdfdf;
}

::-webkit-scrollbar-thumb {
    height: 16px;
    border-radius: 8px;
    background-color: #888888;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a5a4a4;
}

#root {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: 12px;
}

.clearfix {
    clear: both;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.card-content {
    height: 100%;
    background-color: #fff;
    margin: 0 20px 20px 20px;
    padding: 20px;
}

img {
    vertical-align: middle;
}
